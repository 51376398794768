import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';
import { DataService } from '@shared/services/data.service';
import { ManageComponent } from './manage.component';
import { ManageAddressesListComponent } from './addresses/addresses-list/addresses-list.component';
import { ManageAddressesDetailComponent } from './addresses/addresses-detail/addresses-detail.component';
import { ManageContactsListComponent } from './contacts/contacts-list/contacts-list.component';
import { ManageContactsDetailComponent } from './contacts/contacts-detail/contacts-detail.component';

@NgModule({
  providers: [
    DataService
  ],
  declarations: [
    ManageComponent,
    ManageAddressesListComponent,
    ManageAddressesDetailComponent,
    ManageContactsListComponent,
    ManageContactsDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    EmendisBlueprintModule,
  ]
})
export class ManageModule {
}
