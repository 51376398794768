import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { SelectItem } from 'primeng/api';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { Machine } from '@domain/models/machine.model';
import { environment } from '@environments/environment';
import { MachineType } from '@domain/models/machine-type.model';
import { ProjectService } from "@shared/services/project.service";

@Component({
  selector: 'app-options-detail-item',
  templateUrl: './options-detail-item.component.html',
  styleUrls: ['./options-detail-item.component.scss']
})
export class OptionsDetailItemComponent {
  @Input('disabled') disabled: boolean;
  @Input('activity') activity: ProjectActivity;
  @Input('applicable') applicable: boolean;
  @Input('showButton') showButton: boolean;
  @Input('allowDelete') allowDelete: boolean;
  @Input('addressOptions') addressOptions: SelectItem[];

  @Output('activityClick') activityClick = new EventEmitter();
  @Output('onRemoveActivityClick') onRemoveActivityClick = new EventEmitter();

  public localeNL: CalendarLocale = new CalendarLocale();
  public machines: any[] = [];
  public isVanRooyen: boolean;
  public dataLoadedMachines: boolean;

  constructor() {
    this.isVanRooyen = environment.clientName === 'pavanrooyen';
    this.dataLoadedMachines = false;

    if (this.isVanRooyen === true) {
        this.setMachines();
    }
  }

  public onAddActivityClick(type: string): void {
    if (!this.disabled) {
      this.activityClick.emit(type);
    }
  }

  public removeActivityClick(activity: ProjectActivity): void {
    if (!this.disabled) {
      this.onRemoveActivityClick.emit(activity);
    }
  }

  public onSelectMachine(machine): void {
    if (!this.disabled) {
      this.activity.number_trucks = machine !== null ? 1 : 0;
    }
  }

  private async setMachines(): Promise<void> {
    if (!this.disabled) {
      await MachineType.query.each(machineType => this.machines.push({
        label: machineType.name,
        value: machineType.name
      }));
      this.dataLoadedMachines = true;
    }
  }
}
