import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('@root/package.json');

export const environment = {
  production: false,
  name: 'Test',
  applicationName: 'PA Van Rooyen',
  baseUrl: 'https://pavanrooyen.inventorycomplete.test.emendis.nl/api',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  features: {
    arent_materials: true
  },
  db_name: 'pavanrooyen_db',
  clientName: 'pavanrooyen'
};
