import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';

@Component({
  selector: 'app-inventory-add-item',
  templateUrl: 'inventory-add-item.component.html'
})
export class InventoryAddItemComponent implements OnInit {
  @Input('disabled') disabled;
  @Input('inventoryId') inventoryId: number;

  @Output('newItem') newItem = new EventEmitter();

  public form;
  public showErrors = false;

  constructor(private formBuilder: FormBuilder, private projectService: ProjectService) {}

  public ngOnInit(): void {
    this.initForm();
  }


  public initForm(): void {
    this.form = this.formBuilder.group({
      inventory_id: this.formBuilder.control(this.inventoryId),
      name: this.formBuilder.control('', Validators.required),
      remarks: this.formBuilder.control(''),
      volume: this.formBuilder.control('')
    });
  }

  public submit(): void {
    if (this.form.valid) {
      const inventoryItem: InventoryItem = new InventoryItem({ ...this.form.value });
      this.newItem.emit(inventoryItem);
    } else {
      this.showErrors = true;
    }
  }
}
