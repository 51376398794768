import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as uuid from 'uuid/v4';
import { Project } from '@domain/models/project.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { DefaultInventory } from '@domain/models/default-inventory.model';
import { Inventory } from '@domain/models/inventory.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { Client } from '@domain/models/client.model';
import { Observable } from '@node_modules/rxjs';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { environment } from '@environments/environment';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { Picture } from '@domain/models/picture.model';
import * as jwt_decode from 'jwt-decode';

/**
 * ProjectService
 * This service is designed to provide the project accross different classes and routing
 * IMPORTANT: include this service under provider section in a module.
 */
@Injectable()
export class ProjectService {
  private result;

  // Constants
  public maxInventories = 100; // Maximum of inventories to show in list
  public maxInventoryItems = 100; // Maximum of items to show in inventory
  public maxListItems = 50; // MAximum of items to show in default inventory list
  public maxNewDefaultItems = 50; // Maximum of default items to push in new inventory

  // Observables
  public projectLoaded = new Subject<any>();
  public addressLoaded = new Subject<any>();
  public addressAdded = new Subject<any>();
  public quotationAdded = new Subject<any>();
  public contactsChanged = new Subject<any>();
  public contactsAdded = new Subject<any>();
  public clientChanged = new Subject<any>();
  public defaultInventoriesLoaded = new Subject<any>();
  public inventoryDeleted = new Subject<any>();
  public inventoryAdded = new Subject<any>();
  public projectIsReadOnly = new BehaviorSubject<boolean>(false);
  public contactTemplatesUpdated = new Subject<any>();

  // Models
  public project: Project;
  public address: Address;
  public contact: Contact;
  public dataQuery = new QueryOptions();

  private currentClient$ = new Subject<Client>();

  constructor(private dataService: DataService,
              private router: Router,
              private synchronisationService: SynchronisationService) {
  }

  /**
   * Get project
   * If not exist create new project
   */
  public getProject(): any {
    if (!this.project) {
      this.project = new Project({});
      this.project.client_id = null;
    }

    return this.project;
  }

  /**
   * CRUD PROJECT
   */
  public async newProject(): Promise<any> {
    this.project = new Project({});

    // Create guid for new project
    this.project.id = uuid();
    this.project.is_new = true;

    await this.saveProject();

    return this.project;
  }

  public async addDefaultInventories(): Promise<void> {
    const defaultInventories = await DefaultInventory.query.toArray();
    for (const defaultInventory of defaultInventories) {

      if (defaultInventory.name !== 'Kantoor' && defaultInventory.name !== 'School') {
        await this.saveNewInventory(
          new Inventory({
            name: defaultInventory.name,
            default_inventory_id: defaultInventory.id,
            project_id: this.project.id,
            floor: '0',
            building: 'huis'
          })
        );
      }
    }
  }

  /**
   * Load Project by id
   */
  public async loadProject(id: string): Promise<any> {
    this.project = await this.dataService.getById('projects', id);

    if (this.project.is_new === false) {
      await this.synchronisationService.loadSingleProjectData(id);
    }

    if (!this.project) {
      // Project not found, redirect to home screen
      this.router.navigateByUrl('/');
      return;
    }

    await this.project.loadSpecialties();
    await this.project.loadActivities();
    await this.project.loadInventories();
    await this.project.loadQuotations();
    await this.project.loadAddresses();
    await this.project.loadMaterials();
    await this.project.loadPictures();

    this.projectLoaded.next(this.project);
    this.updateProjectReadOnlyStatus(this.project);
  }

  /**
   * Update updated_at field to mark project for synchronisation to backend
   *
   * TODO This synchronisation mark should be more efficient and only applied for real updates
   */
  public setProjectUpdated(): void {
    this.project.is_changed = true;

    if (this.project.id) {
      this.dataService.createOrUpdate('projects', this.project);
    }
  }

  /**
   * Save project with clients
   *
   * @returns {Promise<void>}
   */
  public async saveProject(): Promise<void> {
    const newProjectId = await this.dataService.createOrUpdate('projects', this.project);

    // Check if private project has default projects loaded
    if (this.project.type === 'private' &&
      this.project.reference_nr &&
      this.project.reference_nr.length &&
      (!this.project.inventories || this.project.inventories.length === 0)) {
      await this.addDefaultInventories();
    }

    if (this.project.status === 'lost') {
      // Remove all names from contacts (AVG)
      this.anonymizeProjectContacts();
    }

    this.synchronisationService.setSynchronisingAction(true);

    await this.loadProject(newProjectId);
  }

  public async saveClientAndProject(): Promise<void> {
    await this.dataService.createOrUpdate('clients', this.project.client);

    this.project.client_id = this.project.client.id;

    await this.saveProject();
  }

  /**
   * Delete project with id
   * @param id Number
   */
  public async deleteProject(id): Promise<void> {
    await this.dataService.delete('projects', id);
  }

  /**
   * Save address
   *
   * @param address: Address
   * @param createOrUpdate
   * @returns Promise<void>
   */
  public async saveAddress(address: Address, createOrUpdate: boolean = true): Promise<void> {
    if (createOrUpdate) {
      await this.dataService.createOrUpdate('addresses', address);
    } else {
      await this.dataService.add('addresses', address);
    }

    this.addressAdded.next();
    this.setProjectUpdated();
  }

  /**
   * Save multiple addresses
   * 
   * @param addresses: Address[]
   * @returns Promise<void>
   */
  public async saveAddresses(addresses: Address[]): Promise<void> {
    await Address.query.bulkPut(addresses);
    this.addressAdded.next();
    this.setProjectUpdated();
  }

  /**
   * Save the address index
   * 
   * @param address: Address
   * @returns Promise<void>
   */
  public async saveAddressIndex(address: Address): Promise<void> {
    await Address.query.put(address);
  }

  /**
   * Save address
   *
   * @param quotation
   */
  public saveQuotation(quotation: any): void {
    this.setProjectUpdated();

    // Update date string value
    // TODO Refactor to more sustainable solution
    quotation.updateDate();

    this.dataService.createOrUpdate('quotations', quotation).then(() => {
      this.quotationAdded.next();
    });
  }

  /**
   * Check whether project can be edited by user or not
   *
   * @param project
   */
  public updateProjectReadOnlyStatus(project: Project): void {
    this.projectIsReadOnly.next(
        (project.editing_by && +project.editing_by !== +jwt_decode(localStorage.getItem('token')).sub)
    );
  }

  /**
   * Get address with id
   *
   * @param id Number
   */
  public async getAddress(id: any): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });

    const address = await this.dataService.get('addresses', this.dataQuery, '/address');
    this.address = address[0];
    this.addressLoaded.next(this.address);
  }

  /**
   * Delete address with id
   *
   * @param id Number
   */
  public deleteAddress(id: any): void {
    this.setProjectUpdated();
    this.dataService.delete('addresses', id);
  }

  /**
   * Save contact
   *
   * @param contact Contact model
   */
  public saveContact(contact: any): void {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('contacts', contact).then(result => {
      this.contactsAdded.next();
    });
  }

  /**
   * Get contact with id
   *
   * @param id Number
   */
  public async getContact(id: any): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });

    const contact = await this.dataService.get('contacts', this.dataQuery, '/contact');
    this.contact = contact[0];
    this.contactsChanged.next(this.contact);
  }

  /**
   * Check if project has contacts
   *
   * @returns {Promise<boolean>}
   * @param projectId
   */
  public async hasContacts(projectId: string): Promise<any> {
    if (!this.project) {
      await this.loadProject(projectId);
    }

    if (!this.project || !this.project.client_id) {
      return false;
    }

    const criteria = { client_id: this.project.client_id };
    let contact = await this.dataService
      .getWhere('contacts', criteria, '/contact?client_id=' + this.project.client_id)
      .then(item => (contact = item));

    return contact !== undefined;
  }

  /**
   * Delete contact with id
   *
   * @param id Number
   */
  public deleteContact(id): void {
    this.setProjectUpdated();
    this.dataService.delete('contacts', id);
  }

  /**
   * Get work assignment by id
   */
  public async getWorkAssignment(id: number | string): Promise<any> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });

    const workAssignment = await this.dataService.get('work_assignments', this.dataQuery, '/work_assignment');
    if (workAssignment && workAssignment[0]) {
      workAssignment[0].init();

      return workAssignment[0];
    }
  }

  public async getAllWorkAssignments(id: number | string): Promise<any> {
    this.dataQuery = new QueryOptions({
      columns: [{ name: 'project_id', filter: id, filterMode: 'equals' }]
    });

    const workAssignments = await this.dataService.get('work_assignments', this.dataQuery, '/work_assignment');
    if (workAssignments && workAssignments[0]) {
      for (const assignment of workAssignments) {
        assignment.init();
      }

      return workAssignments;
    }
  }

  /**
   * Save work assignment
   */
  public async saveWorkAssignment(workAssignment: WorkAssignment): Promise<any> {
    this.setProjectUpdated();
    // Update date string value
    // TODO Refactor to more sustainable solution
    workAssignment.updateDate();
    const result = await this.dataService.createOrUpdate('work_assignments', workAssignment);

    // Save items
    for (const item of workAssignment.items) {
      await this.dataService.createOrUpdate('work_assignment_items', item);
    }

    // Save items
    for (const item of workAssignment.address_work_assignments) {
      await this.dataService.createOrUpdate('address_work_assignments', item);
    }
  }

  /**
   * Delete work assignment
   * @param workAssignment
   */
  public async deleteWorkAssignment(workAssignment: WorkAssignment): Promise<void> {
    // First delete items
    await workAssignment.init();
    for (const item of workAssignment.items) {
      await this.dataService.delete('work_assignment_items', item.id);
    }

    this.setProjectUpdated();
    await this.dataService.delete('work_assignments', workAssignment.id);
  }

  public async deleteProjectActivity(activityId: string): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('project_activities', activityId);
  }

  /**
   * Delete work assignment item
   * @param workAssignmentItem
   */
  public async deleteWorkAssignmentItem(workAssignmentItem: WorkAssignmentItem): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('work_assignment_items', workAssignmentItem.id);
  }

  /**
   * Delete work assignment address
   * @param workAssignmentAddress
   */
  public async deleteWorkAssignmentAddress(workAssignmentAddress: WorkAssignmentAddress): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('address_work_assignments', workAssignmentAddress.id);
  }

  /**
   * Save specialties
   *
   * @param specialities Array of specialties
   */
  public async saveSpecialties(specialities): Promise<void> {
    for (const specialty of specialities) {
      // Update date string value
      // TODO Refactor to more sustainable solution
      specialty.updateDate();
      await this.dataService.createOrUpdate('project_specialties', specialty);
    }
  }

  /**
   * Save Picture
   * 
   * @param picture: Picture
   * @returns Promise<void>
   */
  public async savePicture(picture: Picture): Promise<void> {
    await this.dataService.createOrUpdate('pictures', picture);
  }

  /**
   * Delete Picture
   * 
   * @param pictureId: string
   * @returns Promise<void>
   */
  public async deletePicture(pictureId: string): Promise<void> {
    await this.dataService.delete('pictures', pictureId);
  }

  /**
   * Save activites
   *
   * @param activities Array of activities
   */
  public async saveActivities(activities): Promise<void> {
    for (const activity of activities) {
      // Update date string value
      // TODO Refactor to more sustainable solution
      activity.updateDate();
      await this.dataService.createOrUpdate('project_activities', activity);
    }
  }

  /**
   * Get client with id
   * @param id Client id
   */
  public async getClient(id): Promise<void> {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });

    const client = await this.dataService.get('clients', this.dataQuery, '/client');
    this.project.client = client[0];
    this.project.client_id = this.project.client.id;
    this.clientChanged.next(this.project.client);
  }

  /**
   * Create or update inventory_item
   * @param inventoryItem InventoryItem model
   */
  public async createOrUpdateInventoryItem(inventoryItem): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.createOrUpdate('inventory_items', inventoryItem);
  }

  /**
   * Write default_inventory_items to inventory_items for specific inventory
   *
   * @param inventoryId Number
   */
  public async writeDefaultsToInventoryItems(inventoryId): Promise<void> {
    // TODO: add remote route to get default inventory items and default items
    const inventory = await this.dataService.getById('inventories', inventoryId);

    if (!inventory) {
      return;
    }

    const defaultInventoryItems = await this.dataService.getBy(
      'default_inventory_items',
      'default_inventory_id',
      inventory.default_inventory_id
    );

    for (const item of defaultInventoryItems) {
      const defaultItem = await this.dataService.getById('default_items', item.default_item_id);

      if (defaultItem) {
        const inventoryItem = new InventoryItem({
          inventory_id: inventoryId,
          name: defaultItem.name,
          volume: defaultItem.volume,
          meterbox: defaultItem.meterbox
        });

        await this.createOrUpdateInventoryItem(inventoryItem);
      }
    }

    await this.project.loadInventories();
  }

  /**
   * Delete inventory with id
   * @param id Number
   */
  public async deleteInventoryItem(id): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('inventory_items', id);
    await this.project.loadInventories();
  }

  /**
   * Delete inventory with id and assocciated inventory_items
   *
   * @param id Number
   */
  public async deleteInventory(id): Promise<void> {
    this.setProjectUpdated();
    await this.dataService.delete('inventories', id);

    this.dataQuery = new QueryOptions({
      pageSize: this.maxInventoryItems,
      columns: [{ name: 'inventory_id', filter: id, filterMode: 'equals' }]
    });

    this.result = await this.dataService.get('inventory_items', this.dataQuery, '/inventories');
    this.result.forEach(async inventory => {
      await this.dataService.delete('inventory_items', inventory.id);
    });
    await this.project.loadInventories();
    this.inventoryDeleted.next();
  }

  /**
   * Save new inventory
   * In addition write default_inventory_items to inventory_items
   *
   * @param inventory Inventory model
   */
  public async saveNewInventory(inventory): Promise<void> {
    this.setProjectUpdated();
    const newInventoryId = await this.dataService.createOrUpdate('inventories', inventory);
    if (inventory.default_inventory_id != null) {
      await this.writeDefaultsToInventoryItems(newInventoryId);
    } else {
      await this.project.loadInventories();
    }

    this.inventoryAdded.next(newInventoryId);
  }

  /**
   * Update inventory
   *
   * @param inventory Inventory model
   */
  public updateInventory(inventory): void {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('inventories', inventory);
  }

  /**
   * Get default inventories
   */
  public async getDefaultInventories(): Promise<void> {
    const rooms = [];
    this.dataQuery = new QueryOptions({
      pageSize: this.maxListItems,
      columns: [{ name: 'type', filter: this.project.type, filterMode: 'equals' }]
    });

    this.result = await this.dataService.get('default_inventories', this.dataQuery, '/default-inventory/list');
    this.result.forEach(item => {
      rooms.push({ label: item.name, value: item.id });
    }, this);

    this.defaultInventoriesLoaded.next(rooms);
  }

  /**
   * Update project material
   */
  public updateMaterial(projectMaterial: ProjectMaterial): void {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('project_materials', projectMaterial);
  }

  /**
   * Add project material
   */
  public addMaterial(projectMaterial: ProjectMaterial): void {
    this.setProjectUpdated();
    this.dataService.add('project_materials', projectMaterial);
  }

  /**
   * Calculates total volume of all inventories for the current project
   */
  public calculateVolume(): number {
    let volumeTotal = 0;
    if (this.project.inventories && this.project.inventories.length > 0) {
      this.project.inventories.forEach(inventory => {
        if (inventory) {
          let volume = 0;

          if (inventory.items) {
            inventory.items.forEach(item => {
              volume += item.amount * item.volume || 0;
            });
          }

          volumeTotal += volume;
          inventory.volume = Math.round(volume * 100) / 100;
        }
      });
    }

    return Math.round(volumeTotal * 100) / 100;
  }

  public calculatePackingTotal(): number {
    if (!this.project.inventories) {
      return 0;
    }
    let packingTotal = 0;
    for (const inventory of this.project.inventories) {
      packingTotal += +inventory.packing_amount;
    }
    return packingTotal;
  }

  public calculateAssemblyTotal(): number {
    if (!this.project.inventories) {
      return 0;
    }

    let assemblyTotal = 0;
    for (const inventory of this.project.inventories) {
      if (inventory.items) {
        for (const inventoryItem of inventory.items) {
          assemblyTotal += +(inventoryItem.assemble ? 0.5 : 0) + +(inventoryItem.disassemble ? 0.5 : 0);
        }

        assemblyTotal += +inventory.assembly_amount;
      }
    }

    return +assemblyTotal;
  }

  public calculateMeterboxTotal(): number {
    if (!this.project.inventories) {
      return 0;
    }

    let meterboxTotal = 0;
    for (const inventory of this.project.inventories) {
      for (const inventoryItem of inventory.items) {
        // Don't add any boxes when it's a "kast"
        if (!inventoryItem.name.toLowerCase().includes('kast')) {
          meterboxTotal += inventoryItem.amount * inventoryItem.meterbox || 0;
        }
      }
    }

    return meterboxTotal;
  }

  public setCurrentClient(client: Client): void {
    this.currentClient$.next(client);
  }

  public getCurrentClient(): Observable<Client> {
    return this.currentClient$.asObservable();
  }

  /**
   * Remove all names of people from the project (AVG)
   */
  private anonymizeProjectContacts(): void {
    Contact.query.where({ 'client_id': this.project.client_id }).and((contact) => contact.name !== '-').modify({ name: '-' });
  }
}
