import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Material } from '@domain/models/material.model';
import { MaterialGroup } from '@domain/models/material-group.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})
export class MaterialComponent implements OnInit, OnDestroy {
  public project = new Project({});
  public materials: Material[];
  public materialGroups: MaterialGroup[];
  public projectMaterials: ProjectMaterial[];
  public disabled: boolean = true;

  private subscriptionProjectLoaded: Subscription;

  public constructor(private projectService: ProjectService) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });
  }

  public async ngOnInit(): Promise<void> {
    this.project = this.projectService.getProject();

    // Load on project
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(async (project: Project) => {
        this.project = project;
        await this.getMaterials();
      }
    );

    await this.getMaterials();
  }

  public async getMaterials(): Promise<void> {
    this.materialGroups = await MaterialGroup.query.toArray();
    this.projectMaterials = await ProjectMaterial.query
      .where('project_id')
      .equals(this.project.id)
      .toArray();
  }

  public async onMaterialGroupChange(materialGroup: MaterialGroup): Promise<void> {
    // Load materials for selected material group
    const result = await Material.query
      .where('material_group_id')
      .equals(materialGroup.id)
      .toArray();

    // Sort the materials alphabetically
    this.materials = result.sort((a: any, b: any) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();

      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    this.projectService.saveProject();
    this.projectService.setProjectUpdated();
  }

  public getAmount(material: Material): number {
    const item = this.getMaterialItem(material);
    return item ? item.amount : 0;
  }

  public addAmountGlobal(material: Material): void {
    if (!this.disabled) {
      if (environment.clientName === 'pavanrooyen') {
        this.addAmountPAVanRooyen(material);
      } else {
        this.addAmount(material);
      }
    }
  }

  public removeAmountGlobal(material: Material): void {
    if (!this.disabled) {
      if (environment.clientName === 'pavanrooyen') {
        this.removeAmountPAVanRooyen(material);
      } else {
        this.removeAmount(material);
      }
    }
  }

  public addAmountPAVanRooyen(material: Material): void {
    const projectMaterial = this.getMaterialItem(material);

    let amount = 1;

    if (this.isMultipleMaterial(material)) {
      amount = 5;
    }

    if (projectMaterial) {
      const rest = projectMaterial.amount % amount;

      projectMaterial.amount += amount;

      if (rest !== 0) {
        projectMaterial.amount -= rest;
      }

      this.projectService.updateMaterial(projectMaterial);
    } else {
      const newProjectMaterial = new ProjectMaterial({
        project_id: this.project.id,
        material_id: material.id,
        amount: amount
      });

      this.projectMaterials.push(newProjectMaterial);
      this.projectService.updateMaterial(newProjectMaterial);
    }
  }

  public addAmount(material: Material, load: number = 1): void {
    const projectMaterial = this.getMaterialItem(material);

    if (projectMaterial) {
      projectMaterial.amount += load;

      this.projectService.updateMaterial(projectMaterial);
    } else {
      const newProjectMaterial = new ProjectMaterial({
        project_id: this.project.id,
        material_id: material.id,
        amount: 1
      });

      this.projectMaterials.push(newProjectMaterial);
      this.projectService.updateMaterial(newProjectMaterial);
    }
  }

  public async changeAmount(amount: number, material: Material): Promise<void> {
    if (!amount) {
      amount = 0;
    }

    const oldAmount = this.getAmount(material);
    if (oldAmount < amount) {
      await this.addAmount(material, (amount - oldAmount));
    } else if (oldAmount > amount) {
      await this.removeAmount(material, (oldAmount - amount));
    }
  }

  private removeAmountPAVanRooyen(material: Material): void {
    const item = this.getMaterialItem(material);
    let amount = 1;

    if (this.isMultipleMaterial(material)) {
      amount = 5;
    }

    if (item && item.amount > 0) {
      const rest = item.amount % amount;

      if (rest !== 0) {
        item.amount -= rest;
      } else {
        item.amount -= amount;
      }

      if (item.amount < 0) {
        item.amount = 0;
      }

      this.projectService.updateMaterial(item);
    }
  }

  private removeAmount(material: Material, load: number = 1): void {
    const projectMaterial = this.getMaterialItem(material);

    if (projectMaterial && projectMaterial.amount > 0) {
      projectMaterial.amount -= load;
      }

    this.projectService.updateMaterial(projectMaterial);
  }

  private isMultipleMaterial(material: Material): boolean {
    return material && material.name.toLowerCase().includes('etiket');
  }

  private getMaterialItem(material: Material): ProjectMaterial {
    return this.projectMaterials.find((value: ProjectMaterial) => {
      return value.material_id === material.id;
    });
  }
}
