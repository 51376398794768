import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { SelectItem } from 'primeng/api';
import { isNumeric } from '@node_modules/rxjs/internal/util/isNumeric';

@Component({
    selector: 'app-inventory-work-assignment-dropdown-item',
    templateUrl: 'work-assignment-dropdown-item.component.html',
    styleUrls: ['./work-assignment-dropdown-item.component.scss']
})
export class InventoryWorkAssignmentDropdownItemComponent {

    private _item: WorkAssignmentItem;
    get item(): WorkAssignmentItem {
        return this._item;
    }

    @Input()
    set item(item: WorkAssignmentItem) {
        this._item = item;

        if (this._item && this._item.type === 'car') {
            this.updateSelectedCar();
        }
    }

    private _machines: SelectItem[];
    get machines(): SelectItem[] {
        return this._machines;
    }

    @Input()
    set machines(machines: SelectItem[]) {
        this._machines = machines;

        if (this.item && this.item.type === 'car') {
            this.updateSelectedCar();
        }
    }

    @Input('placeholderName') placeholderName;
    @Input('disabled') disabled: boolean;

    @Output('onDeleteItemClick') onDeleteItemClick = new EventEmitter();

    public times: SelectItem[] = [];

    constructor() {
        // Fill times items
        for (let i = 0; i < 24; i++) {
            this.times.push({ label: i + ':00', value: i + ':00' });
            this.times.push({ label: i + ':15', value: i + ':15' });
            this.times.push({ label: i + ':30', value: i + ':30' });
            this.times.push({ label: i + ':45', value: i + ':45' });
        }
    }

    public onDeleteClick(): void {
        this.onDeleteItemClick.emit(this.item);
    }

    private updateSelectedCar(): void {
        if (this.machines && this.machines.length > 0 && this.item.details && !isNumeric(this.item.details)) {
            console.log('machines: ', this.machines);
            console.log('item: ', this.item);
            const machine = this.machines.find(item => item.label === this.item.details);
            console.log('machine: ', machine);

            if (machine) {
                this._item.details = machine.value;
            }
        }
    }
}
